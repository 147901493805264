import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Link } from 'react-router-dom';
import { HashLink } from "react-router-hash-link";
import './PreFooter.module.css';

function PreFooter() {    

    return (
        <div className={['grad'].join(' ')}>
            <div id="prefooter" className="menu_anchor"></div>
            <div className="spacer"></div>
            <div className={['eightypercent', 'margin_top_40p', 'white-text', 'footer_text', ''].join(' ')}>
                <Container fluid>
                    <Row>
                        <Col xs={6} sm={4}>
                            <div className={[].join(' ')}>
                                <h3>More Money</h3>
                                <div><a href="https://moremoney.app/help">Help</a></div>
                                <div><a href="https://moremoney.app/forum">Forum</a></div>
                            </div>
                        </Col>
                        <Col xs={6} sm={4}>
                            <div className={[].join(' ')}>
                                <h3>Company</h3>
                                <div><Link to="mailto:support@moremoney.app">Contact Us</Link></div>
                                <div><HashLink to="/privacypolicy#privacy_top" onClick={() => document.getElementById('privacy_top')?.scrollIntoView({ behaviour: 'smooth' })}>Privacy Policy</HashLink></div>                                                                
                                <div><HashLink to="/cookiepolicy#cookie_top" onClick={() => document.getElementById('cookie_top')?.scrollIntoView({ behaviour: 'smooth' })}>Cookie Policy</HashLink></div>                                
                            </div>
                        </Col>
                        <Col xs={12} sm={4}>                                           
                            <div className={['center_text', 'margin_top_x40'].join(' ')}>
                                <a href="https://www.facebook.com/moremoneyapp" target="_blank" rel="noreferrer" aria-label="More Money on Facebook" className={['hover:text-primary', 'dark:text-primary-dark'].join(' ')}>
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="2.5em" height="1.6em" fill="currentColor">
                                        <path fill="none" d="M0 0h24v24H0z"></path>
                                        <path d="M12 2C6.477 2 2 6.477 2 12c0 4.991 3.657 9.128 8.438 9.879V14.89h-2.54V12h2.54V9.797c0-2.506 1.492-3.89 3.777-3.89 1.094 0 2.238.195 2.238.195v2.46h-1.26c-1.243 0-1.63.771-1.63 1.562V12h2.773l-.443 2.89h-2.33v6.989C18.343 21.129 22 16.99 22 12c0-5.523-4.477-10-10-10z"></path>
                                    </svg>
                                </a>
                                <a href="https://x.com/moremoney.app" target="_blank" rel="noreferrer" aria-label="More Money on X" class="hover:text-primary dark:text-primary-dark">
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" height="2.5em" width="1.6em" fill="currentColor">
                                        <path fill="none" d="M0 0h24v24H0z"></path>
                                        <path d="M389.2 48h70.6L305.6 224.2 487 464H345L233.7 318.6 106.5 464H35.8L200.7 275.5 26.8 48H172.4L272.9 180.9 389.2 48zM364.4 421.8h39.1L151.1 88h-42L364.4 421.8z"></path>
                                    </svg>
                                </a>
                                <a href="https://bsky.app/profile/moremoneyapp.bsky.social" target="_blank" rel="noreferrer" aria-label="More Money on Bluesky" class="hover:text-primary dark:text-primary-dark">
                                    <svg aria-label="Bluesky" viewBox="0 0 16 16" height="1.6em" width="2.9em" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                        <path class="x19hqcy" d="M3.468 1.948C5.303 3.325 7.276 6.118 8 7.616c.725-1.498 2.697-4.29 4.532-5.668C13.855.955 16 .186 16 2.632c0 .489-.28 4.105-.444 4.692-.572 2.04-2.653 2.561-4.504 2.246 3.236.551 4.06 2.375 2.281 4.2-3.376 3.464-4.852-.87-5.23-1.98-.07-.204-.103-.3-.103-.218 0-.081-.033.014-.102.218-.379 1.11-1.855 5.444-5.231 1.98-1.778-1.825-.955-3.65 2.28-4.2-1.85.315-3.932-.205-4.503-2.246C.28 6.737 0 3.12 0 2.632 0 .186 2.145.955 3.468 1.948Z"></path>
                                    </svg>
                                </a>                               
                            </div>                            
                        </Col>
                    </Row>
                </Container>
            </div>            
            &nbsp;
        </div>
    );
}

export default PreFooter;