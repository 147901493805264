import React from 'react';
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Menu from './components/Menu/Menu';
import Home from './components/Home/Home';
import Features from './components/Features/Features';
import Download from './components/Download/Download';
import Pricing from './components/Pricing/Pricing';
import Support from './components/Support/Support';
import Future from './components/Future/Future';
import PreFooter from './components/PreFooter/PreFooter';
import NotFound from './components/NotFound/NotFound';
import Footer from './components/Footer/Footer';
import PrivacyPolicy from './components/Policies/PrivacyPolicy';
import CookiePolicy from './components/Policies/CookiePolicy';
import MSMoney from './components/LandingPages/MSMoney.js';
import Quicken from './components/LandingPages/Quicken.js';
import './App.css';


function App() {
  return (
    <div>
      <Router>
        <Menu />
        <Routes>
          <Route path="/" element={[
              <Home />,
              <Features />,
              <Download />,
              <Future />
            ]} 
          />
          <Route path="/msmoney" element={[
              <Home />,
              <MSMoney />
            ]}
          />
          <Route path="/quicken" element={[
            <Home />,
            <Quicken />
          ]}
          />
          <Route path="/privacypolicy" element={<PrivacyPolicy />} />
          <Route path="/cookiepolicy" element={<CookiePolicy />} />
          <Route path="*" element={[
            <Home />,
            <NotFound />
          ]}
          />
        </Routes>       
        <PreFooter /> 
        <Footer />        
      </Router>      
    </div>
  );
}

export default App;

/*

*/

/*
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/features" element={<Features />} />
        </Routes>
        */

/*
<Router>
        <Menu />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/" element={<Features />} />
          <Route path="*" element={<NotFound />} />
        </Routes>
        <Footer />
      </Router>
      */