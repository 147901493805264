import React from 'react';
import './Future.module.css';


function Future() {
    return (
        <div className={[''].join(' ')}>
            <div id="future" className="menu_anchor"></div>       
            <div className="spacer"></div>
            <div className={['eightypercent', 'margin_top_40p', 'margin_bottom_40p'].join(' ')}>
                <div className="margin_leftright_12">
                    <h1>Future Plans</h1>
                    More Money is currently still a work in progress.  It is already a comprehensive application with many features, but before releasing fully, we plan to implement the following:
                    <div>&nbsp;</div>
                    <ul>                        
                        <li>Detailed Reporting</li>
                        <li>Bank Connectivity</li>
                        <li>Tags</li>
                        <li>Reminders</li>
                        <li>Downloading of prices/rates from custom sources</li>
                    </ul>                    
                </div>
            </div>
            &nbsp;
        </div>
    );
}

export default Future;