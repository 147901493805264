import React from 'react';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
//import { LinkContainer } from "react-router-bootstrap";
import { Link } from 'react-router-dom';
import { useState } from "react";
//import { NavHashLink } from 'react-router-hash-link';
import './Menu.css';


function Menu() {
    const [expanded, setExpanded] = useState(false);
    return (
        <Navbar collapseOnSelect sticky="top" className="color-nav" expand="lg" data-bs-theme="dark">
            <Container>
                <Navbar.Brand as={Link} eventKey="0" to="/" className="menulogo" onClick={() => document.getElementById('home')?.scrollIntoView({ behaviour: 'smooth' })}>More Money</Navbar.Brand>
                <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                <Navbar.Collapse id="responsive-navbar-nav">
                    <Nav>                        
                        <Nav.Link as={Link} eventKey="1" to="/" onClick={() => document.getElementById('features')?.scrollIntoView({ behaviour: 'smooth' })}>Features</Nav.Link>
                        <Nav.Link as={Link} eventKey="2" to="/" onClick={() => document.getElementById('download')?.scrollIntoView({ behaviour: 'smooth' })}>Download</Nav.Link>
                        <Nav.Link as={Link} eventKey="3" to="/" onClick={() => document.getElementById('future')?.scrollIntoView({ behaviour: 'smooth' })}>Future Plans</Nav.Link>
                        <NavDropdown title="Support" id="basic-nav-dropdown">
                            <NavDropdown.Item href="/help">Online Help</NavDropdown.Item>
                            <NavDropdown.Item href="/forum">Forum</NavDropdown.Item>                            
                            <NavDropdown.Divider />
                            <NavDropdown.Item href="mailto:support@moremoney.app">Contact Us</NavDropdown.Item>
                        </NavDropdown>
                    </Nav>
                </Navbar.Collapse>
            </Container>
        </Navbar>        
    );
}

export default Menu;

/*
<NavHashLink as={Link} eventKey="1" to="/#features" onClick={() => document.getElementById('features')?.scrollIntoView({ behaviour: 'smooth' })}>Features</NavHashLink>
                        <NavHashLink as={Link} eventKey="2" to="/#download" onClick={() => document.getElementById('download')?.scrollIntoView({ behaviour: 'smooth' })}>Download</NavHashLink> 
                        <NavHashLink as={Link} eventKey="3" to="/#future" onClick={() => document.getElementById('future')?.scrollIntoView({ behaviour: 'smooth' })}>Future Plans</NavHashLink> 


*/
/*</Container>
<Nav.Link as={Link} eventKey="3" to="#" onClick={() => document.getElementById('pricing')?.scrollIntoView({ behaviour: 'smooth'})}>Pricing</Nav.Link>                        
*/
/*
function Menu() {
    const [expanded, setExpanded] = useState(false);
    return (
        <Navbar expanded={expanded} sticky="top" className="color-nav" expand="md" data-bs-theme="dark">
            <Container>
                <Navbar.Brand as={Link} to="/" className="anton_22" onClick={() => document.getElementById('home')?.scrollIntoView({ behaviour: 'smooth' })}>More Money</Navbar.Brand>
                <Navbar.Toggle
                    aria-controls="navbarScroll"
                    onClick={() => setExpanded(expanded ? false : "expanded")}
                />
                <Navbar.Collapse id="basic-navbar-nav">
                    <Nav>                        
                        <Nav.Link as={Link} to="/features" onClick={() => { document.getElementById('features')?.scrollIntoView({ behaviour: 'smooth' }); setExpanded(false); }}>Features</Nav.Link>
                        <Nav.Link as={Link} to="/download" onClick={() => document.getElementById('download')?.scrollIntoView({ behaviour: 'smooth'})}>Download</Nav.Link>
                        <Nav.Link as={Link} to="/pricing" onClick={() => document.getElementById('pricing')?.scrollIntoView({ behaviour: 'smooth'})}>Pricing</Nav.Link>
                        <Nav.Link as={Link} to="/support" onClick={() => document.getElementById('support')?.scrollIntoView({ behaviour: 'smooth' })}>Support</Nav.Link>
                    </Nav>
                </Navbar.Collapse>
            </Container>
        </Navbar>        
    );
}
*/
/*
<Nav.Link as={Link} to="/features" onClick={() => setExpanded(false)}>Features</Nav.Link>
                        <Nav.Link as={Link} to="/download" onClick={() => setExpanded(false)}>Download</Nav.Link>
                        <Nav.Link as={Link} to="/pricing" onClick={() => setExpanded(false)}>Pricing</Nav.Link>
*/