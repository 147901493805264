import React from 'react';


function NotFound() {
    return (
        <div className={[''].join(' ')}>
            <div id="future" className="menu_anchor"></div>
            <div className="spacer"></div>
            <div className={['eightypercent', 'margin_top_40p', 'margin_bottom_40p'].join(' ')}>
                <div className="margin_leftright_12">
                    <h1>Not Found</h1>
                    The page you requested could not be found
                </div>
            </div>
            &nbsp;
        </div>
    );
}

export default NotFound;