import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import local from './Home.module.css';
import { Link } from 'react-router-dom';


function Home() {
    return (
        <div className={['grad'].join(' ')}>
            <div id="home" className="menu_anchor"></div>       
            <div className="spacer"></div>
            <div className={['eightypercent', 'margin_top_40', ''].join(' ')}>
                <Container fluid>
                    <Row>                    
                        <Col lg={6}>                          
                            <div className={['']}>
                                <div className={['margin_top_80', 'margin_bottom_80', 'white-text', '', 'margin_right40'].join(' ')}>
                                    <h1>More Money</h1>
                                    More Money is a software application for Windows that enables you to organise your Personal Finances
                                    <div>&nbsp;</div>
                                    It's fast, and has a modern user interface that is intuitive and simple to use
                                    <div>&nbsp;</div>
                                    <Link to="#" onClick={() => document.getElementById('download')?.scrollIntoView({ behaviour: 'smooth' })}>
                                        <img width="150px" src="download_white.png" alt="Download"></img>
                                    </Link>
                                </div>                            
                            </div>                                                      
                        </Col>
                        <Col>
                            <div className={[local.monitorCont, 'centre_cont', ''].join(' ')}>
                                <div className={[local.monitorImg, 'centre', ''].join(' ')}>
                                    <figure>
                                        <img id={local.screenshot} src="monitor.png" alt="Screenshot"></img>
                                    </figure>
                                </div>
                            </div>                      
                        </Col>
                    </Row>
                </Container>                
            </div>
            &nbsp;
        </div>        
    );
}

export default Home;

/*

<div>
                                <div className={CSS.monitorImg}>
                                    <figure>
                                        <img id={CSS.screenshot} src="monitor.png" alt="Screenshot"></img>
                                    </figure>
                                </div>
                            </div>
*/