import React from 'react';
import { Link } from 'react-router-dom';
import './Download.css';


function Download() {
    return (
        <div className={['grad'].join(' ')}>            
            <div id="download" className="menu_anchor"></div>
            <div className="spacer"></div>
            <div className={['eightypercent', 'margin_top_40p', 'margin_bottom_40p', 'white-text', ''].join(' ')}>
                <div className={['', 'margin_leftright_12', ''].join(' ')}>
                    <h1>Download</h1>
                    More Money is currently still in development, so downloading is being restricted whilst the software matures.
                    <div>&nbsp;</div>
                    However, if you have time to spare and would like to contribute towards the testing of the application, then please do <Link to="mailto:support@moremoney.app">get in touch</Link>.
                </div>
            </div>                        
            &nbsp;
        </div>
    );
}

export default Download;