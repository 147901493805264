import React from 'react';
import './Landing.module.css';


function MSMoney() {
    return (
        <div className={[''].join(' ')}>
            <div id="future" className="menu_anchor"></div>
            <div className="spacer"></div>
            <div className={['eightypercent', 'margin_top_40p', 'margin_bottom_40p'].join(' ')}>
                <div className="margin_leftright_12">
                    <h1>MS Money Alternative</h1>
                    More Money offers a modern, fast alternative to MS Money, Microsoft's legacy personal finance application.
                    <div>&nbsp;</div>
                    MS Money is no longer supported and has reached end-of-life.  It does not work reliably on Windows 11, it no longer downloads pricing automatically, it no longer has bank connectivity, and the reconciliation process is cumbersome.                    
                    <div>&nbsp;</div>
                    Data from MS Money can be imported into More Money, so you do not have to lose your history.  You can then continue with a modern, supported application which works under Windows 11 and beyond.
                </div>
            </div>
            &nbsp;
        </div>
    );
}

export default MSMoney;
